import {Link, useNavigate} from "react-router-dom";

import logo from '../../assets/imgs/slide2.png'
import {useState} from "react";
import {useAuthStore} from "../../store/user-store";
import {baseApi} from "../../api/axios";
import {useTranslation} from "react-i18next";


export default function LoginComponent() {
    const {t} = useTranslation()
    const redirect = useNavigate()
    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState(null)
    const [code, setCode] = useState(null)
    const [scure, setScure] = useState('password')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const authStore = useAuthStore()

    const changeScure = () => {
        setScure(scure === 'password' ? 'text' : 'password')
    }

    const login = async () => {
        setLoading(true)
        setError('')
        const data = new FormData()
        data.append('username', email)
        data.append('password', password)
        try {
            const response = await baseApi.post('/login', data)
            localStorage.setItem('user', JSON.stringify(response.data?.user))
            if (response.data?.user?.enable_tow_fa_at !== null) {
                setToken(response.data?.token);
            }else{
                localStorage.setItem('token',response.data?.token )
                authStore.setToken(response.data?.token)
                authStore.setUser(response.data?.user)
                redirect('/')
            }
            //

            //
            // authStore.setUser(,)

        } catch (e) {
            setError(e.response.data?.error)
        }
        setLoading(false)

    }

    const verify = async () => {
        setLoading(true)
        setError('')
        const data = new FormData()
        data.append('token', code)

        try {
            const response = await baseApi.post('/verify', data,{headers:{"Authorization":"Bearer "+token}})

            if (response.data?.user?.enable_tow_fa_at !== null) {
                setToken(response.data?.token);
            }


            localStorage.setItem('user', JSON.stringify(response.data?.user))
            localStorage.setItem('token',response.data?.token )
            authStore.setToken(response.data?.token)
             localStorage.setItem('expired_at', response.data?.expired_at)
                authStore.setUser(response.data?.user)
              redirect('/')

        } catch (e) {
            setError(e.response.data?.error)
        }
        setLoading(false)

    }



    if(token==null){
        return (
            <div className={'container'}>
                <div className="row justify-content-center align-items-center">

                    <div className="col-md-8 d-flex justify-content-center align-items-center">
                        <div className="card-login">
                            <div className="d-flex flex-column justify-content-center align-items-center card-auth"
                                 style={{flex: '1 0 auto'}}>
                                <div className={'logo-container'}><img className={'logo-auth'}
                                                                       src={authStore.settings?.logo ?? logo} alt=""/></div>


                                <h2 className={'text-primary my-4'}>{t('login')}</h2>


                                <input type="email" onChange={(e) => setEmail(e.target.value)} value={email}
                                       className="input-form input my-2" placeholder={t('email')}
                                       autoComplete={'off'}/>
                                <div className={'position-relative password-container w-100'}>
                                    <input type={scure} onChange={(e) => setPassword(e.target.value)}
                                           className="input-form input my-2" placeholder={t('password')}
                                           autoComplete={'new-password'}/>
                                    <i onClick={changeScure} className="fa fa-eye position-absolute text-primary"></i>
                                </div>
                                {error.length > 0 ? <span className=" my-2 text-error d-block ">{error}</span> : null}
                                {!loading ?
                                    <button onClick={login} className="btn d-block btn-sm btn-buy ">{t('login')}</button> :
                                    <button className="btn d-block btn-sm submit-btn ">{t('loading')}</button>}
                                <Link to={'/register'}
                                      className="btn d-block btn-sm text-black my-2">{t('no-account')}</Link>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        )
    }else{
        return (
            <div className={'container'}>
                <div className="row justify-content-center align-items-center">

                    <div className="col-md-8 d-flex justify-content-center align-items-center">
                        <div className="card-login">
                            <div className="d-flex flex-column justify-content-center align-items-center card-auth"
                                 style={{flex: '1 0 auto'}}>
                                <div className={'logo-container'}><img className={'logo-auth'}
                                                                       src={authStore.settings?.logo ?? logo} alt=""/></div>


                                <h2 className={'text-primary my-4'}>{t('login')}</h2>

                                <input type="text" name="prevent_autofill" value="" style={{ display: 'none' }} />
                                <span className={'d-block text-right text-text'}>{t('auth-code')}</span>
                                <input type="text" onChange={(e) => setCode(e.target.value)}
                                       className="input-form input my-2" placeholder={t('auth-code')} name={'not_real_field'}
                                       autoComplete={'off'}/>
                                {error?.length > 0 ? <span className=" my-2 text-error d-block ">{error}</span> : null}
                                {!loading ?
                                    <button onClick={verify} className="btn d-block btn-sm btn-buy ">{t('confirm-code')}</button> :
                                    <button className="btn d-block btn-sm submit-btn ">{t('loading')}</button>}
                                <Link to={'/register'}
                                      className="btn d-block btn-sm text-black my-2">{t('no-account')}</Link>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        )
    }

}