export default function CardLoadingComponent({className='col-md-3 col-6'}){
return (
    <div className={`${className}`}>
<div className="loading-card m-block-10">
<div className="loading-img"></div>
    <div className="btn-loading"></div>
</div>
    </div>
)

}