import {ToastContainer} from "react-toastify";
import Layout from "./components/Layout/Layout";
import {Route, Routes} from 'react-router-dom'

import HomePage from "./pages/HomePage";
import CheckUserProvider from "./provider/CheckUserProvider";
import ShowCategoryPage from "./pages/ShowCategoryPage";
import ProductsPage from "./pages/ProductsPage";
import ProductDetailsPage from "./pages/ProductDetailsPage";
import {useAuthStore} from "./store/user-store";
import {baseApi} from "./api/axios";
import {useEffect} from "react";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import BillsPage from "./pages/BillsPage";
import ProfilePage from "./pages/ProfilePage";
import BalancePage from "./pages/BalancePage";
import BankPage from "./pages/BankPage";
import CouponsPage from "./pages/CouponsPage";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import PrivacyPage from "./pages/PrivacyPage";
import AboutPage from "./pages/AboutPage";

/*i18next.use(HttpApi).init(i18nextOptions);*/
i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({


        fallbackLng: "ar",
        detection:{
            order: [ 'localStorage','cookie','htmlTag',/*'querystring',*/  'sessionStorage', 'navigator',  'path', 'subdomain'],
            caches:["localStorage"]
        },
        backend:{
            loadPath:"/locale/{{lng}}/translation.json"
        }

    })

function App() {

    const authStore = useAuthStore()
    const getSettings = async () => {
        const theme = localStorage.getItem('theme') ?? 'light';
        authStore.setTheme(theme)
        try {
            const response = await baseApi('/settings')
            authStore.setSettings(response.data?.setting)

            if (response.data?.user?.id) {
                authStore.setUser(response.data?.user)
                localStorage.removeItem('user')
                localStorage.setItem('user', JSON.stringify(response.data?.user))
            }
        } catch (e) {

        }
    }
    useEffect(() => {
        getSettings()
    }, [])
    return (
        <CheckUserProvider>
            <Layout>
                <ToastContainer/>
                <Routes>
                    <Route path={'/login'} element={<LoginPage/>}/>
                    <Route path={'/register'} element={<RegisterPage/>}/>
                    <Route index element={<HomePage/>}/>
                    <Route path={'/privacy'} element={<PrivacyPage/>}/>
                    <Route path={'/about'} element={<AboutPage/>}/>
                    <Route path={'/categories/:id'} element={<ShowCategoryPage/>}/>
                    <Route path={'/products/:id'} element={<ProductsPage/>}/>
                    <Route path={'/product-details/:id'} element={<ProductDetailsPage/>}/>
                    <Route path={'/bills'} element={<BillsPage/>}/>
                    <Route path={'/coupons'} element={<CouponsPage/>}/>
                    <Route path={'/balances'} element={<BalancePage/>}/>
                    <Route path={'/profile'} element={<ProfilePage/>}/>
                    <Route path={'/banks'} element={<BankPage/>}/>
                </Routes>
            </Layout>
        </CheckUserProvider>
    );
}

export default App;
