import {Link} from "react-router-dom";

export default function ProductItem({product, link}) {
    console.log(product)
    return (
        <div className={`col-md-3 col-6`}>
            {/*   <Link to={link}>
                <div className={'d-flex flex-column  align-items-center justify-content-center product-card'}>
                    <div className="img-product d-flex align-items-center">
                        <div className="over-blur">{product.price} $</div>
                        <img src={product.img} alt={product.name}/>
                    </div>
                    <div className="title">{product.name} </div>
                </div>

            </Link>*/}
            <Link to={link}>
                <div className="card-category m-block-10 position-relative">
                    <div className="position-absolute price "><span className={'text-error-inverse fs-6'}>{product.price.toFixed(2)} $</span></div>
                    <div className="loading-img">
                        <img src={product?.img} alt={product?.name}/>
                    </div>
                    <div className="btn-loading d-flex justify-content-center align-items-center">
                        <span className={'text-white text-bold'}>{product?.name}</span>
                    </div>
                </div>
            </Link>

        </div>

    )
}