import slide1 from  '../../assets/imgs/slide1.jpg'
// import slide1 from  '../../assets/imgs/slide2.png'
import {useAuthStore} from "../../store/user-store";

import Slider from "react-slick";
import LoadingComponent from "../Loading/LoadingComponent";
export default function SliderComponent(){
    const authStore=useAuthStore()
    const settings={
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,
    };
    return (
        <section className={'container container-slider '}>
           <div className="row">
               <div className="col-12 text-center">
                  <div id="mainSlider" className="carousel slide" data-bs-ride="carousel">
                       <div className="carousel-inner slider-shadow">
                           {authStore.settings?.sliders?.length>0?
                               authStore.settings?.sliders?.map((el,index)=>{
                                   return   <div key={index} className={`carousel-item  ${index === 0 ? 'active' : ''}`} style={{backgroundImage: `url(${el})`}}/>
                               })
                               :  <div className="carousel-item  active" style={{backgroundImage: `url(${slide1})`}}/> }


                       </div>
                      {/*  <div className="carousel-indicators">
                    <button type="button" data-bs-target="#mainSlider" data-bs-slide-to="0"
                            className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#mainSlider" data-bs-slide-to="1"
                            aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#mainSlider" data-bs-slide-to="2"
                            aria-label="Slide 3"></button>
                </div>*/}

                       <button className="carousel-control-prev" type="button" data-bs-target="#mainSlider"
                        data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#mainSlider"
                        data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>

                   </div>
               </div>
           </div>
        </section>
    )
}