import {useEffect, useState} from "react";
import LoadingComponent from "../Loading/LoadingComponent";
import CardLoadingComponent from "../Loading/CardLoadingComponent";
import {baseApi} from "../../api/axios";
import NoMoreItemComponent from "../Loading/NoMoreItemComponent";
import ChargeCouponeComponent from "./ChargeCouponeComponent";
import ChargeComponent from "./ChargeComponent";
import {useTranslation} from "react-i18next";

export default function BankSection() {
    const {t}=useTranslation()
    const [loading, setLoading] = useState(false)
    const [loadingBank, setLoadingBank] = useState(false)
    const [bank, setBank] = useState(null)
    const [page, setPage] = useState(1)

    const [banks, setBanks] = useState([])
    const [charges, setCharges] = useState([])
    const getBanks = async () => {
        setLoadingBank(true)
        setBanks([]);
        setBank(null);
        try {
            const response = await baseApi('/banks')
            if (response.data?.banks) {
                setBanks(response.data?.banks)
            }
        } catch (e) {

        }
        setLoadingBank(false)

    }

    const getCharges = async () => {
        setLoading(true)
        try {
            const response = await baseApi(`/charges?page=${page}`)
            if (response.data?.charges?.length > 0) {
                setCharges(response.data?.charges)
            }
        } catch (e) {

        }
        setLoading(false)
    }
    useEffect(() => {
        getBanks()
    }, [])
    useEffect(() => {
        getCharges()
    }, [page])

    return (
        <div className={'container'}>
            <div className="row justify-content-center">
                {loadingBank ?
                    <div className="col-md-3 text-center">
                        <CardLoadingComponent/>
                    </div>
                    : null}

                {!loadingBank ?
                    <div className="col-md-3 text-center">
                        <div className="d-flex flex-column gap-1">
                            <button onClick={() => setBank(null)}
                                    className={`${bank === null ? 'active' : null} btn btn-sm btn-outline-warning`}>{t('view-order-charge')}
                            </button>
                            <button onClick={() => setBank('default')}
                                    className={` ${bank === 'default' ? 'active' : null} btn btn-sm btn-outline-danger`}>{t('charge-coupon')}
                            </button>
                            {banks?.map((el) => {
                                return <button key={el.id} onClick={() => setBank(el)}
                                               className={`btn btn-sm btn-outline-info ${bank?.id === el.id ? 'active' : null}`}>
                                    <span className="d-flex align-items-center gap-1">
                                        <img src={el.img} alt="" className={'bank-img'}/>
                                        <span>{el.name}</span>
                                    </span>
                                </button>
                            })}
                        </div>
                    </div>
                    : null}
                {!loading && bank === null ?
                    <div className={'col-md-9'}>
                        {charges?.length > 0 ?
                            <div className={'table-responsive'}>
                                <table className="table table-hover table-striped table-info">
                                    <thead>
                                    <tr>
                                        <th>{t('pay-way')}</th>
                                        <th>{t('value')}</th>
                                        <th>{t('status')}</th>
                                        <th>{t('attach')}</th>
                                        <th>{t('date')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {charges.map((el) => {
                                        return <tr key={el.id}>
                                            <td>{el.bank}</td>
                                            <td>{el.value}</td>
                                            <td className={`${el.state==='pending'?'text-info':el.state==='canceled'?'text-danger':'text-success'}`}>{el.status}</td>
                                            <td>
                                                {el.img ? <a href={el.img} target={'_blank'}>
                                                    <img src={el.img} alt="" style={{
                                                        width: '50px',
                                                        height: '50px',
                                                        borderRadius: '25px'
                                                    }}/>
                                                </a> : null}
                                            </td>
                                            <td>{el.created_at}</td>
                                        </tr>

                                    })}
                                    </tbody>
                                </table>
                                <div className={'w-100 text-center'}>

                                </div>
                            </div>
                            : <NoMoreItemComponent text={t('no-data')}/>}
                    </div>
                    : null}
                {!loading && bank === 'default' ?
                    <div className={'col-md-9'}>
                        <ChargeCouponeComponent/>
                    </div>
                    : null}
                {!loading && bank?.id?<div className={'col-md-9'}> <ChargeComponent bank={bank}/></div>:null}
                {loading ?
                    <div className="col-md-9 text-center">
                        <LoadingComponent/>
                    </div>
                    : null}

            </div>
        </div>
    )


}