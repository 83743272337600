import axios from 'axios'
import {toast} from "react-toastify";

// export const baseApi=axios.create({baseURL:'http://sooq-card.test/api/v1',withCredentials:true})
export const baseApi = axios.create({baseURL: 'https://store.mazaya-online.com/api/v1', withCredentials: true})
baseApi.interceptors.response.use(
    (response) => {
        // التعامل مع الاستجابة الناجحة
        return response;
    },
    (error) => {
        // التعامل مع الأخطاء
        if (error.response!=null) {
            if (error.response.data?.message &&
                error.response.data?.message?.toLowerCase()?.includes('unauthenticated')) {
                // إزالة البيانات من localStorage
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                console.error('Error Response Inner:', error.response);
                toast.error('إنتهى وقت الجلسة يرجى تسجيل الدخول من جديد')
                // إعادة توجيه المستخدم إلى صفحة تسجيل الدخول
                window.location.replace('/login');
            }
        }


        // بإمكانك أيضاً إظهار رسالة خطأ للمستخدم أو إعادة التوجيه إلى صفحة معينة

        // إعادة رفض الوعد (promise) لإتاحة التعامل مع الخطأ في مكان آخر
        return Promise.reject(error);
    }
);