import {useState} from "react";
import {baseApi} from "../../api/axios";
import {toast} from "react-toastify";
import {useAuthStore} from "../../store/user-store";
import {useTranslation} from "react-i18next";


export default function ChargeComponent({bank}) {
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [amount, setAmount] = useState(0)
    const [note, setNote] = useState('')
    const [file, setFile] = useState(null)
    const authStore = useAuthStore()
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
        }
    }
    const submit = async () => {
        setError('')

        const result = Number.parseFloat(amount)
        if (isNaN(result) || result <= 0) {
            setError(t('valid-data'))
            return;
        }
        if (file == null) {
            setError(t('valid-img'))
            return;
        }
        setLoading(true)
        try {
            const data = new FormData()
            data.append('bank_id', bank.id)
            data.append('amount', amount)
            data.append('info', note)
            data.append('img', file)

            const response = await baseApi.post('/charges', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            if (response.data?.status === 'success') {
                setAmount(0)
                setNote('')
                setFile(null)
                toast.success(t('success-process'))
                window.location.replace('/banks')
            }
        } catch (e) {
            setError(e.response.data?.error)
        }
        setLoading(false)
    }
    return (
        <>
            <div className={'card-login m-auto my-2'}>
                <h1 className={'text-error fs-6 mb-2'}>{t('request-charge')} {bank.name}</h1>
                <div className="d-flex flex-column">
                    <div className={'border border-1 rounded p-2 lh-lg small'}>
                        {bank.description}
                        {bank.iban?.length > 0 ? <span className={'d-flex flex-column small'}>
                    <span>IBAN :</span>
                    <span onClick={() => authStore.copyText(bank.iban)}>{bank.iban} <i
                        className="fa fa-copy text-primary"/></span>
                </span> : null}
                        {bank.wallet_id?.length > 0 ? <span className={'d-flex flex-column small'}>
                    <span>{t('no-wallet')} :</span>
                    <span onClick={() => authStore.copyText(bank.wallet_id)}>{bank.wallet_id} <i
                        className="fa fa-copy text-primary"/></span>
                </span> : null}
                    </div>
                </div>
                <div className="d-flex flex-column mt-2">
                    <span className={'text-text'}>{t('attach')}</span>
                    <input type="file" className="mt-2" onChange={handleFileChange}/>
                    <span className={'d-block text-text'} >{t('amount')}</span>
                    <input type="text" className="input" placeholder={t('value')}
                           onChange={(e) => setAmount(e.target.value)} value={amount}/>
                    <textarea className={'input'} placeholder={t('note')} onChange={(e) => setNote(e.target.value)}
                              value={note}/>
                    {error?.length > 0 ? <span className={'text-error'}>{error}</span> : null}
                    {loading ? <button className="btn btn-sm btn-buy">{t('loading')}</button> :
                        <button onClick={submit} className="btn btn-sm btn-buy">{t('send')}</button>}
                </div>
            </div>
        </>
    )
}