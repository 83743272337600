import {useEffect, useState} from "react";

import {useAuthStore} from "../store/user-store";
import {baseApi} from "../api/axios";


const CheckUserProvider = ({children}) => {
    const authStore = useAuthStore();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        baseApi.defaults.headers.common['Accept-Language'] = localStorage.getItem('i18nextLng') ?? 'ar';
        baseApi.defaults.headers.common['Accept'] = 'application/json';

        // وقت انتهاء التوكن كـ string
        const tokenExpiry = localStorage.getItem('expired_at');

        if (tokenExpiry != null && authStore.user?.enable_tow_fa_at != null) {

            const tokenExpiryDate = new Date(tokenExpiry);
            const currentDate = new Date();
            console.log(currentDate > tokenExpiryDate);
            if (currentDate > tokenExpiryDate) {
                localStorage.removeItem('token');
                authStore.setToken(null)
            }

        }
        if (localStorage.getItem('token')){
            authStore.setToken(localStorage.getItem('token'))
        }


        if (authStore.token) {

            authStore.setUser(JSON.parse(localStorage.getItem('user')));
            baseApi.defaults.headers.common.Authorization = `Bearer ${authStore.token}`;
        }
        setLoading(false);
    }, [authStore.token]);

    return loading ? null : children;
};

export default CheckUserProvider;
