import SliderComponent from "../components/Slider/SliderComponent";
import MarqueeComponent from "../components/Marquee/MarqueeComponent";
import SectionSubCategoryComponent from "../components/Categories/SectionSubCategoryComponent";
import ProductSection from "../components/Products/ProductSection";

export default function  ProductsPage(){

    return(
        <>
        <SliderComponent/>
            <MarqueeComponent/>
            <ProductSection/>

        </>
    )

}