import {useEffect, useState} from "react";
import {baseApi} from "../../api/axios";
import {toast} from "react-toastify";
import {useAuthStore} from "../../store/user-store";
import LoadingComponent from "../Loading/LoadingComponent";
import NoMoreItemComponent from "../Loading/NoMoreItemComponent";
import {useTranslation} from "react-i18next";

export default function ChargeCouponeComponent() {
    const {t} = useTranslation()
    const authStore = useAuthStore()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [loadingCoupons, setLoadingCoupons] = useState(false)
    const [coupon, setCoupon] = useState('')
    const [coupons, setCoupons] = useState([])

    const submit = async () => {
        setLoading(true)
        setError('')
        try {
            const data = new FormData()
            data.append('code', coupon)
            const response = await baseApi.post('/coupons', data)
            if (response.data?.user) {
                authStore.setUser(response.data?.user)
                localStorage.removeItem('user')
                localStorage.setItem('user', JSON.stringify(response.data?.user))
                toast.success(t('success-process'))
                setCoupon('')
            }
        } catch (e) {
            if (e.response.data?.error) {
                setError(e.response.data?.error)
            } else {
                toast.warning(t('server-error'))
            }
        }
        setLoading(false)

    }
    const getCoupons = async () => {
        setLoadingCoupons(true)
        try {
            const response = await baseApi('/getMyCoupon')
            if (response.data?.coupons?.length > 0) {
                setCoupons(response.data?.coupons)
            }

        } catch (e) {
            toast.warning(t('server-error'))
        }
        setLoadingCoupons(false)
    }
    useEffect(() => {
        getCoupons()
    }, [])
    return (
        <>
            <div className={'card-login m-auto my-4'}>
                <h2 className="text-error">{t('enter-coupon')}</h2>
                <input type="text" onChange={(e) => setCoupon(e.target.value)} className="input"
                       placeholder={'رقم الكوبون'} value={coupon}/>
                {error?.length > 0 ? <span className={'text-error d-block my-1'}>{error}</span> : null}
                {loading ? <button className={'btn-buy btn btn-sm'}>{t('loading')}</button> :
                    <button onClick={submit} className={'btn-buy btn btn-sm'}>{t('active')}</button>}
            </div>
            <div className="row">
                <div className="col-12 text-center">
                    {!loadingCoupons && coupons.length>0?  <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th>{t('code')}</th>
                                <th>{t('value')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            { coupons?.map((el)=>{
                                return <tr key={el.id}>
                                    <td>{el.code} <span onClick={()=>authStore.copyText(el.code)}><i className="fa fa-copy text-primary"></i></span></td>
                                    <td>{el.amount}</td>
                                </tr>
                            })}
                            </tbody>
                        </table>
                    </div>:null}
                    {!loadingCoupons && coupons.length===0?<NoMoreItemComponent text={t('no-data')}/>:null}
                    {loadingCoupons?<LoadingComponent/>:null}
                </div>
            </div>
        </>
    )


}