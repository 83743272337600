import {useAuthStore} from "../store/user-store";


export default function AboutPage() {
    const authStore = useAuthStore()
    console.log(authStore.settings?.about)

    return (

        <>
            <div dangerouslySetInnerHTML={{__html: authStore.settings?.about ?? ''}}/>
        </>
    )


}