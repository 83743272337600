import {useEffect, useState} from "react";
import {baseApi} from "../../api/axios";
import {useAuthStore} from "../../store/user-store";
import LoadingComponent from "../Loading/LoadingComponent";
import {toast} from "react-toastify";
import logo from "../../assets/imgs/slide2.png";
import {useTranslation} from "react-i18next";


export default function ProfileComponent() {
    const {t} = useTranslation()
    const authStore = useAuthStore()
    const [scure, setScure] = useState('password')
    const [secret, setSecret] = useState(null)
    const [qr, setQr] = useState(null)
    const [loading, setLoading] = useState(false)
    const [code, setCode] = useState('')
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [email, setEmail] = useState(authStore.user.email)
    const [phone, setPhone] = useState(authStore.user.phone)
    const [username, setUsername] = useState(authStore.user.username)
    const [address, setAddress] = useState(authStore.user.address)
    const [name, setName] = useState(authStore.user.name)
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [errors, setErrors] = useState(null)
    const [file, setFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const changeScure = () => {
        setScure(scure === 'password' ? 'text' : 'password')
    }

    const getProfile = async () => {
        setLoading(true)
        try {
            const response = await baseApi('/profiles')
            if (response.data?.user) {
                authStore.setUser(response.data?.user)
                localStorage.removeItem('user')
                localStorage.setItem('user', JSON.stringify(response.data?.user))
                setPhone(response.data?.user.phone)
                setEmail(response.data?.user.email)
                setName(response.data?.user.name)
                setUsername(response.data?.user.username)
                setAddress(response.data?.user.address)
            }
        } catch (e) {
            console.log(e?.response?.data)
        }
        setLoading(false)
    }


    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setImagePreview(URL.createObjectURL(selectedFile));
            console.log('Selected file:', imagePreview);
        }
    }
    const validateEmail = (text) => {
        return text.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[]\\.,;:\s@\]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const validate = () => {


        if (name.length === 0) {

            setError(t('msg-name'))
            return false;
        }
        if (!validateEmail(email)) {
            setError(t('msg-email'))
            return false;
        }
        if (phone.length === 0) {
            setError(t('required-phone'))
            return false;
        }
        if (password.length > 0 && password.length < 8) {
            setError(t('error-length'))
            return false;
        }
        if (address.length === 0) {
            setError(t('msg-address'))
            return false;
        }

        return true;
    }

    const onSubmit = async () => {
        setErrors([])
        setLoadingSubmit(true)
        if (validate()) {
            try {
                const data = new FormData()
                data.append('name', name)
                data.append('email', email)
                data.append('phone', phone)
                data.append('address', address)
                data.append('username', username)
                data.append('password', password)
                data.append('_method', 'POST')
                data.append('img', file)
                const response = await baseApi.post('/profiles', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                if (response.data?.user) {
                    authStore.setUser(response.data?.user)
                    localStorage.removeItem('user')
                    localStorage.setItem('user', JSON.stringify(response.data?.user))
                    toast.success(t('success-process'))
                }
            } catch (e) {
                console.log(e.response.data)
                setErrors([e.response.data?.msg])
            }
        }
        setLoadingSubmit(false)
    }
    const activate2FA = async () => {
        setLoadingSubmit(true)
        try {
            var res = await baseApi.post('/generate');
            if (res.data?.status === 'success') {
                setSecret(res.data?.secret);
                setQr(res.data?.google2fa_url);
            }
        } catch (e) {

        }
        setLoadingSubmit(false)
    }


    const disabled2FA = async () => {

        if (code.length < 6) {
            return;
        }

        setLoadingSubmit(true)
        try {
            var res = await baseApi.post('/disable', {token: code});
            if (res.data?.status === 'success') {
                setSecret(null);
                setQr(null);
                if (res.data?.user != null) {
                    localStorage.removeItem('expired_at')
                    localStorage.removeItem('user')
                    localStorage.removeItem('token')
                    localStorage.setItem('user', JSON.stringify(res.data?.user));
                    localStorage.setItem('token', res.data?.token)
                    authStore.setToken(res.data?.token )
                    authStore.setUser(res.data?.user)
                }
            }
        } catch (e) {

        }
        setLoadingSubmit(false)
    }
    const enable2FA = async () => {
        if (code.length < 6) {
            return;
        }

        setLoadingSubmit(true)
        try {
            var res = await baseApi.post('/enable', {token: code});
            if (res.data?.status === 'success') {
                setSecret(null);
                setQr(null);
                if (res.data?.user != null) {
                    localStorage.setItem('user', JSON.stringify(res.data?.user));
                    localStorage.setItem('token', res.data?.token)
                    authStore.setUser(res.data?.user)
                    authStore.setToken(res.data?.token)
                }

            }
        } catch (e) {

        }
        setLoadingSubmit(false)
    }
    useEffect(() => {
        getProfile()
    }, [])
    if (loading) {
        return <div className={'container'}>
            <div className="row justify-content-center">
                <div className="col-md-8 text-center">
                    <LoadingComponent/>
                </div>
            </div>
        </div>
    }

    if (!loading && authStore.user != null) {
        return <div className={'container'}>
            <div className="row">
                <div className="col-md-8 d-flex justify-content-center align-items-center m-auto">
                    <div className=" card-profile">
                        <div className="row justify-content-center align-items-center">

                            <h2 className={'text-error fs-4 text-center my-3'}>{t('edit-profile')}</h2>
                            <div className="col-md-8">

                                <div className="d-flex flex-column justify-content-center align-items-center card-auth"
                                     style={{flex: '1 0 auto'}}>
                                    <div className={'logo-container'}>
                                        <label htmlFor="avatar" className={'d-inline-block'}>
                                            {imagePreview && (
                                                <img src={imagePreview} alt="Preview" className={'logo-auth'}/>
                                            )}
                                            {!imagePreview && authStore.user?.avatar?.length > 0 ?
                                                <img className={'logo-auth'}
                                                     src={authStore.user?.avatar}
                                                     alt="asd"/> : null}
                                            {!imagePreview && authStore.user?.avatar?.length === 0 ?
                                                <img className={'logo-auth'}
                                                     src={authStore.settings?.logo ?? logo}
                                                     alt="dsa"/> : null}
                                        </label></div>

                                    <input type="file" accept={'image/jpeg,image/png,image/webp'} id={'avatar'}
                                           className="d-none" onChange={handleFileChange}/>
                                    <span className={'text-orange d-inline-block my-2 small'}>{t('hint-profile')}</span>
                                    <div className="d-flex flex-column flex-grow-1 p-0 m-0 w-100">
                                        <span className={'text-text fs-7'}>{t('name')}</span>
                                        <input type="text" onChange={(e) => setName(e.target.value)}
                                               className="input-form input flex-grow-1 my-2" placeholder={t('name')}
                                               autoComplete={'off'}
                                               value={name}/>
                                    </div>
                                    <div className="d-flex flex-column flex-grow-1 p-0 m-0 w-100">
                                        <span className={'text-text fs-7'}>{t('email')}</span>
                                        <input type="email" onChange={(e) => setEmail(e.target.value)}
                                               className="input-form input flex-grow-1 my-2" placeholder={t('email')}
                                               autoComplete={'new-email'}
                                               value={email}/>
                                    </div>
                                    <div className="d-flex flex-column flex-grow-1 p-0 m-0 w-100">
                                        <span className={'text-text fs-7'}>{t('username')}</span>
                                        <input type="text" onChange={(e) => setUsername(e.target.value)}
                                               className="input-form input flex-grow-1 my-2" placeholder={t('username')}
                                               autoComplete={'new-username'}
                                               value={username}/>
                                    </div>
                                    <div className="d-flex flex-column flex-grow-1 p-0 m-0 w-100">
                                        <span className={'text-text fs-7'}>{t('phone')}</span>
                                        <input type="text" onChange={(e) => setPhone(e.target.value)}
                                               className="input-form input my-2" placeholder={t('phone')}
                                               autoComplete={'off'}
                                               value={phone}/>
                                    </div>
                                    <div className="d-flex flex-column flex-grow-1 p-0 m-0 w-100">
                                        <span className={'text-text fs-7'}>{t('address')}</span>
                                        <input type="text" onChange={(e) => setAddress(e.target.value)}
                                               className="input-form input my-2" placeholder={t('address')}
                                               autoComplete={'off'}
                                               value={address}/>
                                    </div>
                                    <div className={'position-relative password-container w-100'}>
                                        <input type={scure} onChange={(e) => setPassword(e.target.value)}
                                               className="input-form input my-2" placeholder={t('password')}
                                               autoComplete={'new-password'}/>
                                        <i onClick={changeScure}
                                           className="fa fa-eye position-absolute text-primary"></i>
                                    </div>
                                    <div className="d-flex flex-column w-100 justify-content-between">
                                        {!loadingSubmit ?
                                            <button onClick={onSubmit}
                                                    className="btn d-block btn-sm btn-buy ">{t('save')}</button> :
                                            <button className="btn d-block btn-sm btn-buy ">{t('loading')}ة</button>}
                                        <hr/>
                                        {authStore.user.enable_tow_fa_at?.length > 0 ?
                                            <>
                                                <input type={'text'} onChange={(e) => setCode(e.target.value)}
                                                       className="input-form input my-2" placeholder={t('code')}
                                                       autoComplete={'new-password'}/>
                                                <button onClick={disabled2FA}
                                                        className="btn d-block btn-sm btn-gray w-100 ">{t('disable-2fa')}</button>
                                            </>


                                            : !loadingSubmit ? <button onClick={activate2FA}
                                                                       className="btn d-block btn-sm btn-gray w-100 ">{t('active-2fa')}</button>
                                                : <button
                                                    className="btn d-block btn-sm btn-gray w-100 ">{t('loading')}</button>}
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {secret?.length > 0 ?
                    <div className={'col-md-3'}>
                        <div className="d-flex flex-column gap-4">
                            <h3>{t('auth-code')} : <span>{secret}</span></h3>
                            <img src={qr} alt="" className={'img-fluid'}/>
                            <h3>{t('enter-auth-code')}</h3>
                            <input type={'text'} onChange={(e) => setCode(e.target.value)}
                                   className="input-form input my-2" placeholder={t('code')}
                                   autoComplete={'new-password'}/>
                            {!loadingSubmit ? <button onClick={enable2FA}
                                                      className="btn d-block btn-sm btn-gray w-100 ">{t('confirm-code')}</button> :
                                <button
                                    className="btn d-block btn-sm btn-gray w-100 ">{t('loading')}</button>}
                        </div>
                    </div>
                    : null}
            </div>

        </div>
    }
}

