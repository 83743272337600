
import {useAuthStore} from "../store/user-store";


export default function PrivacyPage() {
    const authStore = useAuthStore()



    return (

        <>
            <div dangerouslySetInnerHTML={{__html: authStore.settings?.privacy ?? ''}}/>
        </>
    )


}