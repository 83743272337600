import MarqueeComponent from "../components/Marquee/MarqueeComponent";
import SliderComponent from "../components/Slider/SliderComponent";
import SectionCategoryComponent from "../components/Categories/SectionCategoryComponent";
import {useTranslation} from "react-i18next";


export default function HomePage() {
        const { t } = useTranslation();



        return (

            <>

                <SliderComponent/>
                <MarqueeComponent/>
                <SectionCategoryComponent/>
            </>
        )


}