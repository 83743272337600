import BalanceSection from "../components/Balance/BalanceSection";


export default function BalancePage() {

    return (
        <>
            <BalanceSection/>
        </>
    )
}