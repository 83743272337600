import {useAuthStore} from "../../store/user-store";

export default function MarqueeComponent ({text='مرحبا بكم'}){
    const authStore = useAuthStore()
    return (
        /*<div className={'container mt-2'}>
            <div className="row">
                <div className="col-12">*/
                    <div className={'marquee marquee-container bg-gray'}>
                        <div className={'marquee-content'}>{authStore.settings?.post?.length>0?authStore.settings?.post:text}</div>
                    </div>
                /*</div>
            </div>
        </div>*/
    )
}