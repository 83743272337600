import {useEffect, useState} from "react";
import {baseApi} from "../../api/axios";
import {useAuthStore} from "../../store/user-store";
import {useTranslation} from "react-i18next";

export default function BillItem({order}) {
    const {t} = useTranslation()
    const authStore = useAuthStore()
    const [item, setItem] = useState(order)
    const [loading, setLoading] = useState(false)
    const getStatus = async () => {
        setLoading(true)
        if (item.status === 'pending') {
            try {
                const response = await baseApi(`/bills/${order?.id}`)
                setItem(response.data?.order)
                if (response.data?.user && response.data?.order?.status !== 'pending') {
                    authStore.setUser(response.data?.user)
                    localStorage.setItem('user', JSON.stringify(response.data?.user))
                }
            } catch (e) {
            }
            setLoading(false)
        }

    }
const download=(array)=>{
    let textContent = array.join('\n');

    // إنشاء ملف Blob من النص
    let blob = new Blob([textContent], { type: 'text/plain' });

    // إنشاء رابط URL للملف
    let url = URL.createObjectURL(blob);

    // إنشاء رابط تنزيل
    let a = document.createElement('a');
    a.href = url;
    a.download = 'array.txt';
    document.body.appendChild(a);
    a.click();

    // إزالة الرابط بعد التنزيل
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
}
    useEffect(() => {
        let timer = setInterval(async () => {
            if (!loading) {
                getStatus();
            }
        }, 15000);

        return () => {
            clearInterval(timer);
        };
    }, [loading]);

    return (
        <div className={`card-bill bg-bg ${item.status === 'pending' ? '' : null}`}>
            <div className="d-flex flex-column">
                <div className="d-flex flex-row flex-grow-1 justify-content-between flex-wrap gap-2">
                    <span># {order.id} <span onClick={() => authStore.copyText(order.id)} className={'text-text'}><i
                        className="fa-regular fa-paste"/></span></span>
                    <span><span className={'text-error fs-6'}>{t('status')} : </span>
                        {item.status === 'complete' ? <span className={' text-success'}>{item.status_label} <i className="fa-solid fa-circle-check text-success fa-lg mx-2"/></span> : null}
                        {item.status === 'canceled' ? <span className={' text-danger'}>{item.status_label}<i className="fa-solid fa-rectangle-xmark text-danger fa-lg mx-2"/> </span>: null}
                        {item.status === 'pending' ? <span className={' text-info'}>{item.status_label}<i className="fa-solid fa-recycle text-info fa-lg mx-2"/> </span>: null}
                    </span>
                    <span>{t('qty')} : {item.qty}</span>
                    <span><span className={'text-error fs-6'}>{t('price')} : </span> {order.price.toFixed(2)} $</span>
                </div>
                <div className="d-flex flex-row flex-grow-1 flex-wrap justify-content-between gap-2 my-1">
                     <span className={'fs-6'}><span
                         className={'text-error fs-6'}>{t('product')}  :</span> {order?.product_name} </span>
                    {order?.customer_data?.id?.length > 0 ? <span className={'fs-6'}><span
                        className={'text-error fs-6'}>{t('player-id')} :</span> {order?.customer_data?.id} </span> : null}
                    {order?.customer_data?.url?.length > 0 ? <span className={'fs-6'}><span
                        className={'text-error fs-6'}>{t('url')} :</span> {order?.customer_data?.url}</span> : null}
                    {order?.customer_data?.phone?.length > 0 ? <span className={'fs-6'}><span
                        className={'text-error fs-6'}>{t('phone')} :</span> {order?.customer_data?.phone}</span> : null}
                    {order?.customer_data?.username?.length > 0 ? <span className={'fs-6'}><span
                        className={'text-error fs-6'}>{t('username')} :</span> {order?.customer_data?.username}</span> : null}
                    {order?.customer_data?.password?.length > 0 ? <span className={'fs-6'}><span
                        className={'text-error fs-6'}>{t('password')} :</span> {order?.customer_data?.password}</span> : null}
                    {item?.admin_data?.code?.length > 0 ? <span className={'fs-6'}><span className={'text-error fs-6'}>{t('code')} :</span> {!Array.isArray(item?.admin_data?.code)?item?.admin_data?.code:` ${item?.admin_data?.code.length}  ${t('copy-code')} `}
                        <span onClick={() =>Array.isArray(item?.admin_data?.code)?download(item?.admin_data?.code):null} className={'text-text'}>
                            <i className="fa-solid fa-file-arrow-down fa-xl"></i>
                           </span> </span> : null}
                    {item?.admin_data?.msg?.length > 0 ? <span className={'fs-6'}><span className={'text-error fs-6'}>{t('replay')} :</span> {item?.admin_data?.msg} </span> : null}
                </div>

                <div className="d-flex justify-content-between border-top border-1">
                    <span><i className="fa-solid fa-calendar-days text-error fs-6"></i> {order.date}</span>
                    <span><i className="fa-regular fa-clock text-error fs-6"></i> {order.time}</span>
                </div>

            </div>
        </div>
    )
}

/*


 */