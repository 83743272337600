import SliderComponent from "../components/Slider/SliderComponent";
import MarqueeComponent from "../components/Marquee/MarqueeComponent";
import CouponsSection from "../components/Coupons/CouponsSection";


export default function CouponsPage() {

    return (
        <>
            <SliderComponent/>
            <MarqueeComponent/>
            <CouponsSection/>
        </>
    )
}