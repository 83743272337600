import SliderComponent from "../components/Slider/SliderComponent";
import MarqueeComponent from "../components/Marquee/MarqueeComponent";
import SectionSubCategoryComponent from "../components/Categories/SectionSubCategoryComponent";

export default function  ShowCategoryPage(){

    return(
        <>
        <SliderComponent/>
            <MarqueeComponent/>
            <SectionSubCategoryComponent/>

        </>
    )

}