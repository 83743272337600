import SliderComponent from "../components/Slider/SliderComponent";
import MarqueeComponent from "../components/Marquee/MarqueeComponent";
import SectionSubCategoryComponent from "../components/Categories/SectionSubCategoryComponent";
import ProductSection from "../components/Products/ProductSection";
import ProductDetails from "../components/Products/ProductDetails";

export default function  ProductDetailsPage(){

    return(
        <>
        <SliderComponent/>
            <MarqueeComponent/>
            <ProductDetails/>

        </>
    )

}