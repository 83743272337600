import {create} from "zustand";
import {toast} from "react-toastify";


export const useAuthStore = create((set) => {

    return {
        user: null,
        settings: null,
        theme: 'light',
        token:null,
        setUser: (user) => {
            set({user})

        },
        setToken: (token) => {
            set({token})



        },
        logOut: () => {
            set({user: null})

        },
        setSettings: (settings) => {
            set({settings})

        },
        setTheme: (theme) => {
            localStorage.setItem('theme', theme)
            set({theme})

        },
        copyText: (text) => {
            navigator.clipboard.writeText(text).then(() => {
                toast.success("Copy to clipboard ");
            }).catch(err => {
                toast.error('fail Copy', err);
            });
        }
    }
})