import {Link} from "react-router-dom";

export default function CategoryItemComponent({category,link}){

    return (
        <div className={`col-md-3 col-6 my-2`}>
            <Link to={link}>
                <div className="card-category m-block-10">
                    <div className="loading-img" >
                        <img src={category?.img} alt={category?.name}/>
                    </div>
                    <div className="btn-loading d-flex justify-content-center align-items-center">
                        <span className={'text-white text-bold'}>{category?.name}</span>
                    </div>
                </div>
            </Link>

        </div>
    )
}