import {useEffect, useState} from "react";
import LoadingComponent from "../Loading/LoadingComponent";
import {useAuthStore} from "../../store/user-store";
import {Link, useNavigate} from "react-router-dom";
import {baseApi} from "../../api/axios";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

export default function ProductCodePay({product}) {
    const {t}=useTranslation()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [qty, setQty] = useState(1)
    const authStore = useAuthStore();
    const redirect = useNavigate()
    const increase = () => {
        setQty(qty + 1)
    }
    const decrease = () => {
        if (qty > 1) {
            setQty(qty - 1)
        }
    }
    const
        submit = async () => {
            setLoading(true)
            setError('')
            try {
                const formData = new FormData()
                formData.append('product_id', product.id)
                formData.append('qty', qty)
                const response = await baseApi.post('/bills', formData)
                if (response.data?.user) {
                    localStorage.setItem('user', JSON.stringify(response.data?.user))
                    authStore.setUser(response.data?.user);
                }
                toast.success(t('bay-success'))
                redirect('/bills')
            } catch (e) {
                setError(e.response.data?.msg ?? e.response.data?.error)
            }
            setLoading(false)
        }
    if (authStore.user?.id) {
        return (
            <>
                <div className="text-center w-100 my-3">
                    <span className={'text-danger small'}>{error}</span>
                    {loading ? <LoadingComponent/> :
                        <div className={'d-flex flex-column justify-content-center align-items-center gap-2'}>
                            <div className="d-flex flex-row justify-content-center align-items-center">
                                <button onClick={increase} className={'btn-increase'}>
                                    +
                                </button>
                                <span>{qty}</span>
                                <button onClick={decrease} className={'btn-increase'}>
                                    -
                                </button>
                            </div>
                            <button onClick={submit} className={'btn-buy'}>{t('bay')}</button>
                        </div>}
                </div>
            </>


        )
    } else {
        return (
            <>
                <div className="text-center w-100 my-3">
                    <Link to={'/login'} className={'btn-buy'}>تسجيل الدخول</Link>
                </div>
            </>


        )
    }

}



