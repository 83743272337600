import {useEffect, useState} from "react";
import {baseApi} from "../../api/axios";
import LoadingComponent from "../Loading/LoadingComponent";
import NoMoreItemComponent from "../Loading/NoMoreItemComponent";
import {useAuthStore} from "../../store/user-store";
import {useTranslation} from "react-i18next";

export default function BalanceSection() {
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const [next, setNext] = useState(null)
    const [back, setBack] = useState(null)
    const [page, setPage] = useState(1)
    const [balances, setBalances] = useState([])
    const [url, setUrl] = useState('balances')
    const authStore = useAuthStore()
   const changeUrl=(url)=>{
        setPage(1);
        setUrl(url)
    }
    const getBalances = async () => {
        setLoading(true)
        setBalances([])
        setNext(null)
        setBack(null)
        try {
            const response = await baseApi(`/${url}?page=${page}`)
            if (response.data?.balances?.length > 0) {
                setBalances(response.data?.balances)
                setNext(response.data?.next)
                setBack(response.data?.back)
                if (page === 1 && response.data?.user?.id) {
                    authStore.setUser(response.data?.user)
                    localStorage.removeItem('user')
                    localStorage.setItem('user', JSON.stringify(response.data?.user))
                }
            }
        } catch (e) {
            console.log(e)
        }
        setLoading(false)

    }
    const changePage = (num) => {

        if (page > 0 && num > 0) {
            setPage(num)
        }
    }
    useEffect(() => {
        getBalances()
    }, [page, url])


        return (
            <>
                <div className={'container'}>
                    <div className="row justify-content-center">
                        <div className={`col-6 col-md-3 card-balance ${url === 'balances' ? 'active' : null}`}
                             onClick={() => changeUrl('balances')}>
                            <h4>{t('current-balance')}</h4>
                            <span>{authStore.user.balance.toFixed(2)} $</span>
                        </div>
                        <div className={`col-6 col-md-3 card-balance ${url === 'debits' ? 'active' : null}`}
                             onClick={() => changeUrl('debits')}>
                            <h4>{t('current-debit')}</h4>
                            <span>{authStore.user.debit?.toFixed(2)??0} $</span></div>
                    </div>
                    {loading?
                        <div className="row justify-content-center">
                            <div className="col-md-6 text-center"><LoadingComponent/></div>
                    </div>:null }
                    {!loading && balances?.length > 0?  <div className="row">
                        <div className="col-12 text-center">
                            <div className="table-responsive">
                                <table className="table table-hover table-border">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{t('credit')}</th>
                                        <th>{t('debit')}</th>
                                        <th>{t('total')}</th>
                                        <th>{t('info')}</th>
                                        <th>{t('date')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {balances.map((el) => {
                                        return <tr key={el.id}>
                                            <td className={`small ${Number.parseFloat(el.credit) > 0 ? 'bg-success-subtle' : 'bg-warning-subtle'}`}>{el.id}</td>
                                            <td className={`small ${Number.parseFloat(el.credit) > 0 ? 'bg-success-subtle' : 'bg-warning-subtle'}`}>{el.credit}</td>
                                            <td className={`small ${Number.parseFloat(el.credit) > 0 ? 'bg-success-subtle' : 'bg-warning-subtle'}`}>{el.debit}</td>
                                            <td className={`small ${Number.parseFloat(el.credit) > 0 ? 'bg-success-subtle' : 'bg-warning-subtle'}`}>{el.total}</td>
                                            <td className={`small ${Number.parseFloat(el.credit) > 0 ? 'bg-success-subtle' : 'bg-warning-subtle'}`}>{el.info}</td>
                                            <td className={`small ${Number.parseFloat(el.credit) > 0 ? 'bg-success-subtle' : 'bg-warning-subtle'}`}>{el.date}</td>
                                        </tr>
                                    })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            {back != null ? <button className={'btn btn-sm btn-outline-danger mx-2'}
                                                    onClick={() => changePage(page - 1)}>{t('next')}</button> : null}
                            {next != null ? <button className={'btn btn-sm btn-outline-danger mx-2'}
                                                    onClick={() => changePage(page + 1)}>{t('prev')}</button> : null}


                        </div>
                    </div>:null}
                    {!loading && balances?.length === 0? <>  <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <NoMoreItemComponent text={t('no-data')}/>
                        </div></div></>:null}
                </div>
            </>
        )



}